import {Container, Row, Button} from 'react-bootstrap'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faTwitter,faShopify,syringe} from "@fortawesome/free-brands-svg-icons";

function Location() {

  
    // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2799.2146802983693!2d-75.79643538730076!3d45.445329870953074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cce02e4a39708b3%3A0xa321d323554bbe46!2s32%20Rue%20de%20la%20Bourrasque%2C%20Gatineau%2C%20QC%20J9A%200G1%2C%20Canada!5e0!3m2!1sen!2sus!4v1732775547536!5m2!1sen!2sus"
    return (
        <>
       <div className="location-wrap pad" id="location-wrap">
                <iframe className="map-center"   src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2799.2146802983693!2d-75.79643538730076!3d45.445329870953074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cce02e4a39708b3%3A0xa321d323554bbe46!2s32%20Rue%20de%20la%20Bourrasque%2C%20Gatineau%2C%20QC%20J9A%200G1%2C%20Canada!5e0!3m2!1sen!2sus!4v1732775547536!5m2!1sen!2sus" width="" height="450" loading="lazy" ></iframe>
      </div>
   </>
    
    )
}

export default Location