import React, { useEffect } from "react";
import {Container, Row, Col, Form} from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faTwitter, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import { faPhone, faEnvelope, faLocationDot} from '@fortawesome/free-solid-svg-icons';
import * as yup from 'yup';
import { useFormik } from 'formik';
import axios from "axios";
import { LangContext } from './LangProvider';
import { useContext,useState} from 'react'
import ContactUsContent from './lang/ContactUsContent';
import Alert from 'react-bootstrap/Alert';
// import { Header } from "react-bootstrap/lib/modal";


function Contact() {

  const { lang, setLang } = useContext(LangContext);
  const [message, setMessage] = useState('');
  
  const PageContent = ContactUsContent()[lang];

  const validationSchema = yup.object().shape({
    firstname: yup
      .string()
      .required(PageContent.FirstNameError)
      .min(3, PageContent.FirstNameMinError),
    email: yup  
      .string() 
      .required(PageContent.EmailError)  
      .email('Enter valid email id'),
    phone: yup
     .string()
     .required(PageContent.PhoneError)
     .min(10, 'Minimum 10 digit required')
     .matches(new RegExp('[0-9]{7}')),
     message: yup
      .string()
      .required(PageContent.MessageError)
});
    const formik = useFormik({
        initialValues: {
        firstname: '',
        lastname: '',
          email:'',
          phone:'',
        message: ''
          
        },
        onSubmit: values => {
            var datas = {
              firstname: values.firstname,
              lastname: values.lastname,
              email : values.email,
              phone : values.phone,
              message: values.message,

          }
        //   axios.defaults.headers = {
        //     'Content-Type': 'application/json',
        //     Authorization: 'myspecialpassword'
        // }
              // axios.post("/sendmail.php", datas)
              //   .then(function (response) {
              //     setMassage(response.data);
              //     setTimeout(() => {
              //       setMassage('');
              //     }, 1000);
              // })
              // .catch(function(error) {
              //   console.log(error);
              // });
          axios({
            method: "POST",
            url: "/sendmail.php",
            data: datas,
            Header: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            console.log("eeeer",response.data);
            
              setMessage(response.data);
              setTimeout(() => {
                setMessage('');
              }, 2000);
            
          })
            .catch(function(error) {
              console.log(error);
            });
          formik.resetForm();
        },
        validationSchema: validationSchema
      });
      const renderErrorMessage = field => {
        return (
          formik.touched[field] && (
            <div class="text-error">{formik.errors[field]}</div>
          )
        );
  };

  
  const reanderSuccessMessage = () => {
    if (message !== '' && message !== undefined && message !== null) {  
      return (
        <Alert variant="success">
          {message}
        </Alert>
      );
    }
  };

     


    return (
        <div className="contact-wrap pad">
          <Container>
           <Row className="align-items-center">
           <Col md={12}>
              <div className="heading-section">
                <h2>{PageContent.title }</h2> 
                <p>{PageContent.Anyquestions }</p>
             </div>
           </Col>
           </Row>   
           <Row className="contact-bg bg-white shadow align-items-center">
            <Col md={12} lg={5}>
                <div className="get-in">
                <h4>{PageContent.GetInTouch }</h4>
                <p>{PageContent.GetInTouchText }</p>
                    <ul>
                        <li><a href="tel:+16132885375"><div className="img-box"><FontAwesomeIcon icon={faPhone} /></div>(613) 288-5375</a></li>
                        <li><a href="mailto:info@generositefundraising.com"><div className="img-box"><FontAwesomeIcon icon={faEnvelope} /></div>info@generositefundraising.com</a></li>
                  <li><a href="#location-wrap"><div className="img-box"><FontAwesomeIcon icon={faLocationDot} /></div>32 rue de la Bourrasque, Gatineau, QC J9A 0C9</a></li>
                    </ul>
                    <div className="contact-icon-box">
                    <a href="https://www.facebook.com/generositefundraising" target="_blank"><FontAwesomeIcon icon={faFacebookF} /></a>
                    <a href="https://www.instagram.com/generosite_fundraising/" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a>
                    <a href=" https://www.linkedin.com/company/generosite/" target="_blank"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                    </div>  
                </div>
            </Col>
            <Col md={12} lg={7}>
                <div className="form-out">
                
                    <Form onSubmit={formik.handleSubmit}>
                      <Row className="align-items-center">
                        <Form.Group as={Col} column sm={6} className="mb-3" controlId="formGridFirstname">
                      <Form.Label>{PageContent.FirstName }</Form.Label>
                            <Form.Control type="text" placeholder="" {...formik.getFieldProps('firstname')}/>
                            {renderErrorMessage('firstname')}
                        </Form.Group>
                        <Form.Group as={Col} column sm={6} className="mb-3" controlId="formGridEmail">
                         <Form.Label>{PageContent.LastName }</Form.Label>
                      <Form.Control type="text" placeholder="" {...formik.getFieldProps('lastname')} name="lastname" />
                     
                        </Form.Group>
                        <Form.Group as={Col} column sm={6} className="mb-3" controlId="formGridEmail">
                         <Form.Label>{PageContent.Email }</Form.Label>
                            <Form.Control type="email" placeholder="" {...formik.getFieldProps('email')} name="email"/>
                            {renderErrorMessage('email')}
                        </Form.Group>
                        <Form.Group as={Col} column sm={6} className="mb-3" controlId="formGridnumber" {...formik.getFieldProps('email')}>
                         <Form.Label>{PageContent.Phone }</Form.Label>
                            <Form.Control type="number" placeholder="" name="phone" {...formik.getFieldProps('phone')}/>
                            {renderErrorMessage('phone')}
                        </Form.Group>
                        <Form.Group as={Col} column sm={12} className="mb-3" controlId="exampleForm.ControlTextarea1">
                          <Form.Label>{PageContent.Message }</Form.Label>
                           <Form.Control as="textarea" rows={3} placeholder="" name="message" {...formik.getFieldProps('message')}/>
                           {renderErrorMessage('message')}
                         </Form.Group>
                         <Form.Group as={Col} column sm={12} className="">
                      <button type="submit" className="btn btn-primary mb-2">{PageContent.submit}</button> 
                    </Form.Group>
                    
                  </Row>
                </Form>
                {reanderSuccessMessage()}
                </div>
            </Col>            
           </Row>
          </Container>   
        </div>
    )
}

export default Contact