import React from 'react'

export default function ContactUsContent() {
  return (
      {
          "en": {
              "title": "Contact Us",
              "Anyquestions": "Any questions or remarks ? Just write us a message!",
              "GetInTouch": "Get in touch",
              "GetInTouchText": "Fill up the form and our team will  get back to you within 24 hours",
              "FirstName": "First Name",
              "LastName": "Last Name",
              "Email": "Email",
              "Phone": "Phone",
              "Message": "Message",
          "FirstNameError": "First name is required",
              "FirstNameMinError": "Minimum 3 characters required",
              "EmailError": "Email ID is required",
              "PhoneError": "phone number is required",
          "MessageError": "Message  is required",
              "submit": "Submit",
               "location": "32 Bourrasque Street, Gatineau, QC J9A 0C9f", 
          },
          "fr": {
              "title": "Contactez-nous",
              "Anyquestions": "Des questions ou remarques ? Écrivez-nous simplement un message!",
              "GetInTouch": "Pour nous joindre",
              "GetInTouchText": "Remplissez le formulaire et notre équipe vous répondra dans les 24 heures",
              "FirstName": "Prénom",
              "LastName": "Nom de famille",
              "Email": "Courriel",
              "Phone": "Téléphone",
              "Message": "Message",
              "FirstNameError": "Le prénom est requis",
              "EmailError": "L’adresse courriel est requise",
              "PhoneError": "Le numéro de téléphone est requis",
            "MessageError": " Le message est requis", 
            "submit": "Soumettre",
            "FirstNameMinError": "Minimum 3 caractères requis",
            "location": "32 rue de la Bourrasque, Gatineau, QC J9A 0C9f",
          },
    }
  )
}
